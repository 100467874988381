import React from "react";
import "./SpinnerComponent.css";

const SpinnerComponent = ({ visible }) => {
  if (!visible) return null;

  return (
    <div className="spinner-overlay">
      <div className="spinner"></div>
    </div>
  );
};

export default SpinnerComponent;
