import React from "react";
import { useParams } from "react-router-dom";
import NutritionForm from "../../components/NutritionForm/NutritionForm";
import heroImage from "../../assets/hero-image.jpg";
import { Helmet } from "react-helmet";

const Home = () => {
  const { affiliateCode } = useParams();
  const domain = process.env.REACT_APP_DOMAIN;
  const imageUrl = `${domain}/assets/logo.png`;

  return (
    <>
      <Helmet>
        <title>MaxNutrition - Planuri Nutriționale Personalizate</title>
        <meta
          name="description"
          content="MaxNutrition oferă planuri nutriționale personalizate, concepute pentru a te ajuta să atingi obiectivele de sănătate și fitness. Descoperă puterea unei diete echilibrate!"
        />
        <meta
          name="keywords"
          content="MaxNutrition, nutriție personalizată, planuri nutriționale, sănătate, fitness, dietă echilibrată, obiective de fitness"
        />
        <meta name="author" content="Echipa MaxNutrition" />
        <meta
          property="og:title"
          content="MaxNutrition - Planuri Nutriționale Personalizate"
        />
        <meta
          property="og:description"
          content="Descoperă planuri nutriționale personalizate cu MaxNutrition. Fie că dorești să slăbești, să te menții sau să câștigi masă musculară, avem soluția ideală pentru tine."
        />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={domain} />
        <meta property="og:type" content="website" />
        <meta
          name="twitter:title"
          content="MaxNutrition - Planuri Nutriționale Personalizate"
        />
        <meta
          name="twitter:description"
          content="Alătură-te MaxNutrition pentru a accesa planuri nutriționale personalizate, create special pentru a-ți susține obiectivele de sănătate și fitness."
        />
        <meta name="twitter:image" content={imageUrl} />
        <meta
          name="twitter:card"
          content="Descoperă planuri nutriționale personalizate cu MaxNutrition. Fie că dorești să slăbești, să te menții sau să câștigi masă musculară, avem soluția ideală pentru tine."
        />
        <meta name="twitter:site" content="@MaxNutrition" />
        <meta name="twitter:creator" content="@MaxNutrition" />
      </Helmet>
      <div>
        <section
          className="hero-section bg-cover bg-center text-white text-center p-20"
          style={{ backgroundImage: `url(${heroImage})` }}
        >
          <h1 className="text-4xl font-bold">
            Bine ai venit în comunitatea noastră de nutriție!
          </h1>
          <p className="mt-4">
            Descoperă cum să îți transformi viața prin alegeri alimentare
            conștiente.
          </p>
        </section>

        <div className="container mx-auto p-4 flex flex-col md:flex-row justify-between">
          <div className="form-section md:w-2/3 p-4 bg-white shadow-lg rounded-lg">
            <h2 className="text-2xl font-bold text-center text-blue-600">
              Începe călătoria ta acum!
            </h2>
            <p className="mt-4 text-lg text-gray-600">
              Completează formularul de mai jos pentru a primi planul tău
              personalizat:
            </p>
            <div className="mt-6">
              <NutritionForm affiliateCode={affiliateCode} />
            </div>
          </div>

          <div className="benefits-section md:w-1/3 p-4 bg-white shadow-lg rounded-lg">
            <h2 className="text-2xl font-bold text-center text-blue-600">
              De ce să alegi un plan de nutriție personalizat?
            </h2>
            <ul className="list-disc pl-8 mt-4 text-gray-700">
              <li className="mt-2">
                Îmbunătățește-ți sănătatea și bunăstarea generală
              </li>
              <li className="mt-2">
                Primești un plan adaptat nevoilor și obiectivelor tale unice
              </li>
              <li className="mt-2">
                Învață să faci alegeri alimentare inteligente și durabile
              </li>
              <li className="mt-2">
                Sprijin continuu de la specialiștii noștri în nutriție
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
