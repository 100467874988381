import React from "react";
import { Link } from "react-router-dom";
import logoImage from "../assets/logo.png";

const Header = () => {
  return (
    <header className="bg-blue-500 text-white p-4 flex justify-between items-center">
      <div className="logo">
        <img src={logoImage} alt="MaxNutrition Logo" className="h-16 md:h-24" />
      </div>
      <nav>
        <ul className="flex gap-4">
          <li>
            <Link to="/" className="hover:text-gray-200">
              Home
            </Link>
          </li>
          <li>
            <Link to="/contact" className="hover:text-gray-200">
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
