import React, { useState, useEffect } from "react";
import { questions } from "../../constants/questions";
import "./NutritionForm.css";
import TermsModal from "../TermsModal/TermsModal";
import { createPlanOrder } from "../../services/PaymentService";
import { loadStripe } from "@stripe/stripe-js";

const NutritionForm = ({ affiliateCode }) => {
  const effectiveAffiliateCode = affiliateCode || "MaxNutrition";
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [errors, setErrors] = useState({});
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    // Inițializăm Stripe la încărcarea componentei folosind cheia din variabilele de mediu
    const initializeStripe = async () => {
      const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
      setStripe(stripeInstance);
    };

    initializeStripe();
  }, []);

  const openTermsModal = () => setIsTermsModalOpen(true);
  const closeTermsModal = () => setIsTermsModalOpen(false);

  const handleAnswerChange = (questionId, value) => {
    setAnswers({ ...answers, [questionId]: value });
    // Încercăm să ștergem eroarea atunci când utilizatorul începe să scrie
    if (errors[questionId]) {
      delete errors[questionId];
      setErrors({ ...errors });
    }
  };

  const handleCheckboxChange = (questionId, optionValue) => {
    const updatedAnswers = { ...answers };
    updatedAnswers[questionId] = updatedAnswers[questionId] || [];
    if (updatedAnswers[questionId].includes(optionValue)) {
      updatedAnswers[questionId] = updatedAnswers[questionId].filter(
        (value) => value !== optionValue
      );
    } else {
      updatedAnswers[questionId].push(optionValue);
    }
    setAnswers(updatedAnswers);
  };

  const handleCheckboxSingleChange = (questionId, newValue) => {
    // Actualizează răspunsul pentru întrebarea curentă cu noua valoare selectată
    // Dacă valoarea este deja selectată, o deselectează (comportament toggle)
    const updatedAnswers = {
      ...answers,
      [questionId]: answers[questionId] === newValue ? "" : newValue,
    };

    // Actualizează starea globală a răspunsurilor sau starea locală, după caz
    setAnswers(updatedAnswers);
  };

  const validateAndMoveNext = () => {
    const currentQuestion = questions[currentQuestionIndex];
    const answer = answers[currentQuestion.id];
    if (
      !answer ||
      (currentQuestion.regex && !new RegExp(currentQuestion.regex).test(answer))
    ) {
      setErrors({
        ...errors,
        [currentQuestion.id]:
          currentQuestion.errorMessage ||
          "Răspuns invalid sau câmp obligatoriu.",
      });
      return;
    }
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const renderQuestionInput = (question) => {
    switch (question.type) {
      case "select":
        return (
          <select
            id={question.id}
            value={answers[question.id] || ""}
            onChange={(e) => handleAnswerChange(question.id, e.target.value)}
            className="select-input"
          >
            <option value="">Selectează...</option>
            {question.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
      case "checkbox":
        return (
          <div className="checkbox-container">
            {question.options.map((option) => (
              <label
                key={option.value}
                className={`checkbox-panel ${
                  answers[question.id]?.includes(option.value) ? "selected" : ""
                }`}
              >
                <input
                  type="checkbox"
                  id={`${question.id}-${option.value}`}
                  name={question.id}
                  value={option.value}
                  checked={
                    answers[question.id]?.includes(option.value) || false
                  }
                  onChange={() =>
                    handleCheckboxChange(question.id, option.value)
                  }
                  className="hidden"
                />
                <img
                  src={option.image || "https://via.placeholder.com/100x50"}
                  alt={option.label}
                  className="checkbox-image"
                />
                <span>{option.label}</span>
              </label>
            ))}
          </div>
        );
      case "checkboxsingle":
        return (
          <div className="checkbox-container">
            {question.options.map((option) => (
              <label
                key={option.value}
                className={`checkbox-panel ${
                  answers[question.id] === option.value ? "selected" : ""
                }`}
              >
                <input
                  type="checkbox"
                  id={`${question.id}-${option.value}`}
                  name={question.id}
                  value={option.value}
                  checked={answers[question.id] === option.value || false}
                  onChange={() =>
                    handleCheckboxSingleChange(question.id, option.value)
                  }
                  className="hidden"
                />
                <img
                  src={option.image || "https://via.placeholder.com/100x50"}
                  alt={option.label}
                  className="checkbox-image"
                />
                <span>{option.label}</span>
              </label>
            ))}
          </div>
        );
      default:
        return (
          <input
            type={question.type}
            id={question.id}
            placeholder={question.placeholder}
            value={answers[question.id] || ""}
            onChange={(e) => handleAnswerChange(question.id, e.target.value)}
            className="input"
          />
        );
    }
  };

  const pay = async () => {
    if (!stripe) return;

    answers.affiliateCode = effectiveAffiliateCode;

    await createPlanOrder(answers)
      .then((response) => {
        stripe.redirectToCheckout({ sessionId: response.result.id });
      })
      .catch((error) => {
        console.error("Eroare la crearea comenzii de plan:", error);
      });
  };

  return (
    <>
      <div className="form-container">
        {currentQuestionIndex < questions.length ? (
          <>
            <div className="question-label">
              {questions[currentQuestionIndex].label}
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
              {renderQuestionInput(questions[currentQuestionIndex])}
              {errors[questions[currentQuestionIndex].id] && (
                <p className="error-message">
                  {errors[questions[currentQuestionIndex].id]}
                </p>
              )}
              <div className="navigation-buttons">
                {currentQuestionIndex > 0 && (
                  <button
                    onClick={() =>
                      setCurrentQuestionIndex(currentQuestionIndex - 1)
                    }
                    className="prev-button"
                  >
                    Înapoi
                  </button>
                )}
                <button onClick={validateAndMoveNext} className="next-button">
                  Următorul
                </button>
              </div>
            </form>
          </>
        ) : (
          <div className="completion-message">
            <p>Ai completat toate întrebările!</p>
            <p>
              Verifică{" "}
              <button
                onClick={openTermsModal}
                className="text-blue-500 underline"
              >
                Termeni și condiții
              </button>{" "}
              înainte de a continua.
            </p>
            <button onClick={pay} className="generate-btn">
              Generează răspuns
            </button>
          </div>
        )}
      </div>
      <TermsModal isOpen={isTermsModalOpen} onClose={closeTermsModal} />
    </>
  );
};

export default NutritionForm;
