import axios from "axios";
import { Plan } from "../models/PlanModel";
import { MaxNutritionRequest } from "../models/MaxNutritionRequest";
import { OrderedTemplate } from "../models/OrderedTemplate";

const API_URL = process.env.REACT_APP_BASE_URL + "/api/MaxNutritionService";

export const createPlanOrder = async (userProfileData) => {
  const orderedTemplateInstance = new OrderedTemplate(
    process.env.REACT_APP_STRIPE_TEMPLATE_ID, // TemplateId
    "MaxNutrition Plan", // Title
    "MaxNutrition Plan Description" // Description
  );

  // Crearea instanței NutritionRequest
  const nutritionRequestInstance = new MaxNutritionRequest(
    userProfileData.fullName,
    userProfileData.age,
    userProfileData.gender,
    userProfileData.goal,
    userProfileData.activityLevel,
    userProfileData.dietaryRestrictions,
    userProfileData.email,
    userProfileData.favoriteFoods,
    userProfileData.healthConcerns
  );

  const planInstance = new Plan(
    process.env.REACT_APP_DOMAIN, // webdomain
    true, // acceptedTermsAndConditions
    userProfileData.affiliateCode,
    orderedTemplateInstance,
    nutritionRequestInstance
  );

  try {
    const response = await axios.post(`${API_URL}/PlaceOrder`, planInstance, {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating plan order:", error);
    throw error;
  }
};

export const CheckoutSuccess = async (sessionId) => {
  try {
    const response = await axios.get(`${API_URL}/Success`, {
      params: { sessionId },
    });

    return response.data;
  } catch (error) {
    return null;
  }
};
