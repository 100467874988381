import React from "react";
import "./PaymentCancelled.css";

const PaymentCancelled = () => {
  return (
    <div className="thank-you-container">
      <div className="card">
        <h1>Planul Nutrițional Anulat</h1>
        <p>Your nutrition plan has been canceled.</p>
        <p>
          Ne pare rău că ai decis să nu continui cu planul tău nutrițional
          personalizat. Înțelegem că fiecare călătorie este unică și că uneori
          pot apărea schimbări de planuri.
        </p>
        <p>
          Dacă ai întrebări sau dacă te răzgândești și dorești să reiei
          procesul, echipa noastră este aici pentru a te ajuta. Suntem dedicati
          să te sprijinim în călătoria ta către un stil de viață mai sănătos.
        </p>
        <p>
          Te încurajăm să ne contactezi pentru orice nelămuriri sau pentru a
          discuta alternativele care ți se potrivesc cel mai bine.
        </p>

        <div className="footer">
          <p>Echipa de Nutriție</p>
        </div>
      </div>
    </div>
  );
};

export default PaymentCancelled;
