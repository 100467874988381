import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CheckoutSuccess } from "../../services/PaymentService";
import SpinnerComponent from "../SpinnerComponent/SpinnerComponent";
import "./PaymentSuccess.css";

const PaymentSuccess = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { sessionId } = useParams();

  // Verificați și stocați sessionId în localStorage la încărcarea inițială
  useEffect(() => {
    const fetchCheckoutSuccess = async () => {
      if (!sessionId) return;

      try {
        // Verificați dacă sessionId există deja în localStorage
        const storedSessionId = localStorage.getItem("sessionId");
        if (!storedSessionId || storedSessionId !== sessionId) {
          localStorage.setItem("sessionId", sessionId);
          await CheckoutSuccess(sessionId);
        }
      } catch (error) {
        console.error("Eroare la procesarea succesului checkout-ului:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCheckoutSuccess();
  }, [sessionId]);

  return (
    <>
      <SpinnerComponent visible={isLoading} />
      <div className="thank-you-container">
        <div className="card">
          <h1>Mulțumim pentru achiziția dvs.!</h1>
          <p>Bună ziua,</p>
          <p>
            Felicitări pentru alegerea ta de a investi în sănătatea ta! Echipa
            noastră de profesioniști pasionați lucrează intens pentru a crea
            Planul tău de Psiho-Nutriție Personalizat. În curând, vei primi o
            hartă detaliată, adaptată nevoilor tale unice.
          </p>
          <p>
            În timp ce aștepți, începe să reflectezi asupra transformărilor
            uimitoare care te așteaptă. Suntem aici pentru a te ghida în această
            călătorie de autodescoperire și îmbunătățire continuă.
          </p>
          <p>Mulțumim pentru încrederea acordată!</p>
          <div className="footer">
            <p>Echipa de Nutriție</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
