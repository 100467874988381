import { MaxNutritionRequest } from "./MaxNutritionRequest";
import { OrderedTemplate } from "./OrderedTemplate";

export class Plan {
  constructor(
    webdomain,
    acceptedTermsAndConditions,
    affiliateCode,
    orderedTemplate,
    nutritionRequest
  ) {
    this.Webdomain = webdomain;
    this.AcceptedTermsAndConditions = acceptedTermsAndConditions;
    this.AffiliateCode = affiliateCode;
    this.EnterpriseType = "NutritionApp";
    this.EnterpriseName = "MaxNutrition";
    this.OrderedTemplate = new OrderedTemplate(
      orderedTemplate.TemplateId,
      orderedTemplate.Title,
      orderedTemplate.Description
    );
    this.NutritionRequest = new MaxNutritionRequest(
      nutritionRequest.fullName,
      nutritionRequest.age,
      nutritionRequest.gender,
      nutritionRequest.goal,
      nutritionRequest.activityLevel,
      nutritionRequest.dietaryRestrictions,
      nutritionRequest.email,
      nutritionRequest.favoriteFoods,
      nutritionRequest.healthConcerns,
      ""
    );
  }
}
