import React from "react";

const NotFoundComponent = () => {
  return (
    <div>
      <h1>404 Not Found</h1>
      <p>Pagina pe care o cauți nu a fost găsită.</p>
    </div>
  );
};

export default NotFoundComponent;
