export class MaxNutritionRequest {
  constructor(
    fullName,
    age,
    gender,
    goal,
    activityLevel,
    dietaryRestrictions,
    email,
    favoriteFoods,
    healthConcerns,
    prompt
  ) {
    this.fullName = fullName;
    this.age = age;
    this.gender = gender;
    this.goal = goal;
    this.activityLevel = activityLevel;
    this.dietaryRestrictions = dietaryRestrictions;
    this.email = email;
    this.favoriteFoods = favoriteFoods;
    this.healthConcerns = healthConcerns;
    this.prompt = prompt;
  }
}
