import React from "react";

const TermsModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded-lg max-w-3xl w-full overflow-y-auto">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 mt-4 mr-4 text-2xl font-semibold"
          aria-label="Închide"
        >
          &times;
        </button>
        <h2 className="text-lg font-bold">Termeni și Condiții</h2>
        {/* Transformă paragrafele în titluri h1 și aplică stiluri specifice */}
        <h1 className="mt-8 text-xl font-bold">Scopul acestui serviciu:</h1>
        <p>Acest site web oferă planuri de nutriție cu scop informativ...</p>

        <h1 className="mt-8 text-xl font-bold">Consiliere medicală:</h1>
        <p>
          Înainte de a începe orice plan de nutriție sau de a face schimbări
          semnificative...
        </p>

        <h1 className="mt-8 text-xl font-bold">Rezultate individuale:</h1>
        <p>
          Rezultatele pot varia în funcție de mai mulți factori, inclusiv
          vârsta, sexul...
        </p>

        <h1 className="mt-8 text-xl font-bold">
          Responsabilitatea utilizatorului:
        </h1>
        <p>
          Utilizatorii sunt în întregime responsabili pentru orice decizie de a
          urma un plan...
        </p>

        <h1 className="mt-8 text-xl font-bold">Confidențialitatea datelor:</h1>
        <p>
          Datele introduse în acest serviciu pentru generarea planurilor de
          nutriție vor fi tratate...
        </p>

        <h1 className="mt-8 text-xl font-bold">Actualizări și corectări:</h1>
        <p>
          Planurile de nutriție generate pot fi revizuite și actualizate
          periodic...
        </p>

        <h1 className="mt-8 text-xl font-bold">Întrebări și clarificări:</h1>
        <p>
          Pentru întrebări sau clarificări suplimentare cu privire la planurile
          de nutriție generate...
        </p>

        <p className="mt-4">
          Prin folosirea acestui site web, vă exprimați acordul cu termenii și
          condițiile menționate mai sus...
        </p>

        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        >
          Închide
        </button>
      </div>
    </div>
  );
};

export default TermsModal;
