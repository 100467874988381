import React from "react";
import logoImage from "../assets/maxnutrition_logo.png";

const Footer = () => {
  return (
    <footer className="bg-gray-200 text-center p-4 mt-4 flex flex-col items-center">
      <div className="flex items-center justify-center w-full">
        <img src={logoImage} alt="MaxNutrition Logo" className="h-16 mr-4" />{" "}
        {/* Logo mai mare */}
        <div>
          <p className="text-lg">
            Descoperă puterea nutriției cu MaxNutrition.
          </p>
          <p>
            Alătură-te comunității noastre pentru sfaturi, rețete și multe
            altele.
          </p>
        </div>
      </div>
      <p className="mt-4 w-full text-sm">
        Urmărește-ne pe social media pentru ultimele noutăți.
      </p>
      <p className="mt-4 w-full">
        © 2024 MaxNutrition. Toate drepturile rezervate.
      </p>
    </footer>
  );
};

export default Footer;
