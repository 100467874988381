import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./components/Home/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import CookiesPolicy from "./components/CookiesPolicy";
import PaymentSuccess from "./components/PaymentSuccess/PaymentSuccess";
import PaymentCancelled from "./components/PaymentCancelled/PaymentCancelled";
import NotFoundComponent from "./components/NotFound";
function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Navigate replace to="/partner/" />} />
          <Route path="/partner/" element={<Home />} />
          <Route path="/partner/:affiliateCode" element={<Home />} />
          <Route path="/success/:sessionId" element={<PaymentSuccess />} />
          <Route path="/cancel" element={<PaymentCancelled />} />
          <Route path="/404" element={<NotFoundComponent />} />
        </Routes>
        <main className="p-4">
          <CookiesPolicy />
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
